
/* eslint-disable vue/no-mutating-props */
import { ref, watch, PropType, defineComponent } from 'vue'
import { payOrder, checkOrder } from '@/api/sales'
import { wxPay } from '@/states/sales'
import { ProductVo, OrderInfo, PayData, buildPayData } from 'momai'
import { Notify } from 'vant'
import { getOpenid } from '@/utils/auth'
export default defineComponent({
  props: {
    curProduct: {
      type: Object as PropType<ProductVo>,
      default: null
    },
    orderInfo: {
      type: Object as PropType<OrderInfo>,
      default: null
    },
    paySuccess: {
      type: Function as PropType<() => void>,
      default: () => {}
    },
    params: {
      type: Object,
      default: null
    }
  },
  setup: (props) => {
    const restFeeType = () => {
      if(Number(props.orderInfo.payFee) > 0 ){
        props.orderInfo.feeType = 0
      }else{
        props.orderInfo.feeType = 1
      }
    }
    restFeeType()
    const resetFee = (feeType: number | string) => {
      if (feeType === 0) {
        props.orderInfo.payFee = props.orderInfo.fee = props.curProduct.fee
        props.orderInfo.payFeeStr = (props.orderInfo.payFee / 100).toFixed(2) + ' 元'
      } else {
        props.orderInfo.payFee = props.orderInfo.fee = props.curProduct.score
        props.orderInfo.payFeeStr = props.orderInfo.payFee + ' 魔豆数'
      }
    }
    resetFee(props.orderInfo.feeType)
    watch(() => props.orderInfo.feeType, newValue => {
      resetFee(newValue)
    })
    const afterPay = (f: boolean) => {
      if (f) {
        Notify({ type: 'success', message: '付款成功' })
        props.params.show = false
        props.orderInfo.status = 2
        props.paySuccess()
      } else {
        Notify({ type: 'danger', message: '付款失败' })
        props.params.step = 2
      }
    }
    const payData = ref<PayData>(buildPayData())
    watch(() => payData.value.flag, newValue => {
      if (newValue != null) {
        if (newValue === 0) {
          afterPay(true)
        } else {
          afterPay(false)
        }
      }
    })
    const payFn = () => {
      props.orderInfo.openid = getOpenid()
      payOrder(props.orderInfo).then(r => {
        if (props.orderInfo.feeType === 1) {
          afterPay(true)
        } else {
          props.params.step = 2
          payData.value = JSON.parse(r)
          wxPay(payData.value)
        }
      })
    }
    return {
      payFn,
      closeFn: () => {
        props.params.show = false
      },
      checkOrderFn: () => {
        checkOrder(props.orderInfo.id).then(r => {
          if (r) {
            afterPay(true)
          } else {
            afterPay(false)
          }
        })
      }
    }
  }
})
